import {SVGAttributes} from 'react';

const ReplayIcon = ({fill = '#333', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill="#F5F5F5" />
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke="#EEE" />
    <g clipPath="url(#clip0_5192_10255)">
      <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.664 10.719a.448.448 0 01-.075-.06.463.463 0 01-.057-.602l.005-.008c.015-.02.03-.04.048-.057l1.784-2.05a.467.467 0 01.662-.043.473.473 0 01.044.663l-.869.994A6.691 6.691 0 0114.5 9.43a5.894 5.894 0 015.887 5.888c0 3.243-2.637 5.9-5.887 5.9a5.894 5.894 0 01-5.888-5.888c0-1.169.344-2.3.994-3.275a.472.472 0 01.65-.131.472.472 0 01.131.65 4.953 4.953 0 004.119 7.7 4.953 4.953 0 004.95-4.95 4.959 4.959 0 00-4.956-4.95c-.501 0-.993.061-1.463.185l1.263.921c.206.15.256.444.1.657a.443.443 0 01-.375.193.495.495 0 01-.275-.087l-2.086-1.524z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_5192_10255">
        <path fill="#fff" transform="translate(7 7)" d="M0 0H15V15H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ReplayIcon;
