import {RouterProvider, createBrowserRouter} from 'react-router-dom';

import {AllPermission} from '~variables';
import {
  LoginPage,
  LayoutPage,
  NotFoundPage,
  DevicePage,
  MediaContentPage,
  ProfilePage,
  AddMediaContentPage,
} from '~pages';

import WrapperRoute from './WrapperRoute';

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <WrapperRoute
        auth={false}
        element={<LoginPage />}
        title="Login"
        permission={AllPermission.All__Allowed}
      />
    ),
  },
  {
    path: '/',
    element: (
      <WrapperRoute element={<LayoutPage />} title="" permission={AllPermission.All__Allowed} />
    ),
    children: [
      {
        index: true,
        element: (
          <WrapperRoute
            element={<DevicePage />}
            title="Device"
            permission={AllPermission.Devices__Read}
          />
        ),
      },
      {
        path: 'media-content',
        children: [
          {
            index: true,
            element: (
              <WrapperRoute
                element={<MediaContentPage />}
                title="Media Content"
                permission={AllPermission.Contents__Read}
              />
            ),
          },
          {
            path: 'add',
            element: (
              <WrapperRoute
                element={<AddMediaContentPage />}
                title="Add Media Content"
                permission={AllPermission.Contents__Read}
              />
            ),
          },
          {
            path: 'add/:mediaContentId',
            element: (
              <WrapperRoute
                element={<AddMediaContentPage />}
                title="Edit Media Content"
                permission={AllPermission.Contents__Read}
              />
            ),
          },
        ],
      },
      {
        path: '/profile',
        element: (
          <WrapperRoute
            element={<ProfilePage />}
            title="Profile"
            permission={AllPermission.All__Allowed}
          />
        ),
      },
      {
        path: '*',
        element: (
          <WrapperRoute
            element={<NotFoundPage />}
            title="Not Found"
            permission={AllPermission.All__Allowed}
          />
        ),
      },
    ],
  },
]);

const RenderRoutes = () => {
  return <RouterProvider router={router} />;
};

export const navigate = router.navigate;

export default RenderRoutes;
