import {notification} from 'antd';
import {HttpStatusCode} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {getSearchParamsWindow, uploadFile} from '~utils';
import {MediaContentFilterParams} from '~models';
import {
  addMediaContentAPI,
  deleteMediaContentAPI,
  editMediaContentAPI,
  editMediaContentStatusAPI,
  getMediaContentsAPI,
  replayMediaContentAPI,
} from '~services';

import {
  AddMediaContentPayload,
  DeleteMediaContentPayload,
  EditMediaContentPayload,
  EditMediaContentStatusPayload,
  GetMediaContentsPayload,
  ReplayMediaContentPayload,
} from './action-models';

export const getMediaContentsThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/GET_MEDIA_CONTENTS_THUNK',
  async ({setIsLoading, callback}: GetMediaContentsPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<MediaContentFilterParams>();

      const response = await getMediaContentsAPI({
        page: searchParams.current_page,
        per_page: searchParams.per_page,
        search: searchParams.search,
        created_at: searchParams.created_at,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const addMediaContentThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/ADD_MEDIA_CONTENT_THUNK',
  async ({payload, callback}: AddMediaContentPayload) => {
    try {
      const attachment = await uploadFile(payload.attachment as File);
      const response = await addMediaContentAPI({
        name: payload.name,
        description: payload.description,
        attachment: attachment?.id as string,
        device_ids: payload.devices.map(d => d.id),
      });

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thêm mới thành công',
          description: 'Thêm mới nội dung thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thêm mới thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const editMediaContentThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/EDIT_MEDIA_CONTENT_THUNK',
  async ({payload, callback}: EditMediaContentPayload) => {
    try {
      const isAttachmentChanged = !!payload.attachment?.type;
      const editMediaContentAPIPayload = {
        id: payload.id,
        name: payload.name,
        attachment: payload.attachment?.id,
        description: payload.description,
        device_ids: payload.devices.map(d => d.id),
      };

      if (isAttachmentChanged) {
        const attachment = await uploadFile(payload.attachment as File);
        editMediaContentAPIPayload.attachment = attachment?.id;
      }

      const response = await editMediaContentAPI(editMediaContentAPIPayload);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Cập nhật thành công',
          description: 'Cập nhật nội dung thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Cập nhật thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const editMediaContentStatusThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/EDIT_MEDIA_CONTENT_STATUS_THUNK',
  async ({payload, callback}: EditMediaContentStatusPayload) => {
    try {
      const response = await editMediaContentStatusAPI(payload);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thao tác thành công',
          description: 'Thao tác thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thao tác thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const deleteMediaContentThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/DELETE_MEDIA_CONTENT_THUNK',
  async ({id, callback}: DeleteMediaContentPayload) => {
    try {
      const response = await deleteMediaContentAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá nội dung thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);

export const replayMediaContentThunk = createAsyncThunk(
  'MEDIA_CONTENT_SLICE/REPLAY_MEDIA_CONTENT_THUNK',
  async ({id, callback}: ReplayMediaContentPayload) => {
    try {
      const response = await replayMediaContentAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Thao tác thành công',
          description: 'Thao tác thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Thao tác thất bại',
        description: error.response.data.message,
      });
    }
  },
);
