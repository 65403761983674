import {SVGAttributes} from 'react';

const DeviceIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 15.95v4.52a.749.749 0 01-.75.75H5.74c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2.51V16.7H5.21C1.5 16.7.306 15.594.252 12.004a.74.74 0 01-.001-.09L.25 11.74v-6C.25 1.94 1.41.78 5.21.78h10.53c3.8 0 4.96 1.16 4.96 4.96 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-2.94-.52-3.46-3.46-3.46H5.21c-2.94 0-3.46.52-3.46 3.46v5.46H9c.41 0 .75.34.75.75s-.34.75-.75.75H1.776c.134 2.098.848 2.5 3.434 2.5h3.67a.755.755 0 01.87.74v.01zm8.29 5.27h-3.55c-2.77 0-3.71-.94-3.71-3.71V11.8c0-2.77.94-3.71 3.71-3.71h3.55c2.77 0 3.71.94 3.71 3.71v5.71c0 2.78-.94 3.71-3.71 3.71zM14.49 9.59c-1.96 0-2.21.25-2.21 2.21v5.71c0 1.96.25 2.21 2.21 2.21h3.55c1.96 0 2.21-.25 2.21-2.21V11.8c0-1.96-.25-2.21-2.21-2.21h-3.55zm1.1 8.09c.19.19.45.29.71.29.07 0 .13 0 .2-.02.06-.01.12-.03.18-.05.06-.03.12-.06.17-.1.06-.03.11-.07.16-.12.09-.09.16-.2.21-.32.05-.13.08-.26.08-.39a1.008 1.008 0 00-.45-.83c-.05-.03-.11-.06-.17-.09l-.18-.06c-.33-.06-.68.05-.91.28-.18.18-.29.44-.29.7 0 .13.03.26.08.39.05.12.12.23.21.32z"
      fill="#fff"
    />
  </svg>
);

export default DeviceIcon;
