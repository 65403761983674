import {DeviceIcon, MediaContentIcon} from '~assets';

import {AllPermission} from './all-permission';

export const menuItems = [
  {
    key: '/',
    icon: <DeviceIcon />,
    label: 'Quản lý thiết bị',
    permission: AllPermission.Devices__Read,
  },
  {
    key: '/media-content',
    icon: <MediaContentIcon />,
    label: 'Quản lý nội dung',
    permission: AllPermission.Contents__Read,
  },
  // {
  //   key: '/group-permission',
  //   icon: <GroupPermissionIcon />,
  //   label: 'Phân quyền',
  //   permission: AllPermission.Group_permissions__Read,
  // },
];
