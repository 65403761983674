import {SVGAttributes} from 'react';

const PauseIcon = ({fill = '#999', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill="#F5F5F5" />
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke="#EEE" />
    <g clipPath="url(#clip0_5138_75204)">
      <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.131 20.593H12.4c1.162 0 1.725-.538 1.725-1.65v-8.888c0-1.112-.563-1.65-1.725-1.65h-2.269c-1.162 0-1.725.538-1.725 1.65v8.888c0 1.112.563 1.65 1.725 1.65zm-.787-10.538c0-.543.112-.712.787-.712H12.4c.681 0 .787.169.787.712v8.888c0 .543-.112.712-.787.712h-2.269c-.681 0-.787-.169-.787-.712v-8.888zM16.6 20.593h2.269c1.162 0 1.725-.538 1.725-1.65v-8.888c0-1.112-.563-1.65-1.725-1.65H16.6c-1.162 0-1.725.538-1.725 1.65v8.888c0 1.112.563 1.65 1.725 1.65zm-.787-10.538c0-.543.112-.712.787-.712h2.269c.681 0 .787.169.787.712v8.888c0 .543-.112.712-.787.712H16.6c-.681 0-.787-.169-.787-.712v-8.888z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_5138_75204">
        <path fill="#fff" transform="translate(7 7)" d="M0 0H15V15H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PauseIcon;
