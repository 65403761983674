import {SVGAttributes} from 'react';

const DeleteIcon = ({fill = '#333', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill="#F5F5F5" />
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke="#EEE" />
    <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.987 9.213l-.133.795a37.49 37.49 0 00-1.754.136l-1.275.125a.472.472 0 00.1.938l1.275-.126c3.269-.33 6.569-.206 9.875.126h.05a.463.463 0 00.462-.426.47.47 0 00-.418-.512 72.06 72.06 0 00-3.019-.236v-.002l-.138-.812v-.004c-.094-.575-.234-1.434-1.694-1.434h-1.637c-1.455 0-1.593.83-1.693 1.43v.002zm4.106.162l.104.613a48.204 48.204 0 00-3.384-.02l.105-.6.002-.005c.092-.551.107-.638.767-.638h1.638c.662 0 .681.106.768.65zm-3.6 11.843h4.013c2.181 0 2.269-1.206 2.337-2.18l.407-6.295a.47.47 0 00-.438-.5.477.477 0 00-.5.438l-.406 6.294c-.063.95-.087 1.306-1.4 1.306h-4.013c-1.305 0-1.33-.356-1.4-1.305v-.001l-.406-6.294a.474.474 0 00-.5-.438.473.473 0 00-.437.5l.406 6.294c.069.975.156 2.181 2.337 2.181zm3.044-3.437h-2.081a.472.472 0 01-.469-.468c0-.257.213-.47.469-.47h2.081c.256 0 .469.213.469.47a.472.472 0 01-.469.468zm-2.6-2.5h3.125a.472.472 0 00.469-.469.472.472 0 00-.469-.468h-3.125a.472.472 0 00-.468.469c0 .256.212.468.468.468z"
      fill={fill}
    />
  </svg>
);

export default DeleteIcon;
