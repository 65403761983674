import {Modal} from 'antd';

import {Attachment, UPLOAD_STATUS} from '~models';
import {uploadVideoAPI} from '~services';

import {formatDateTime} from './common';
import {showLoadingModal} from './modal';

export const uploadFile = async (file: File): Promise<Attachment | undefined> => {
  const modalInfo = Modal.info({
    open: false,
    footer: null,
    icon: null,
    width: 300,
  });

  const body = new FormData();
  body.append('title', `video_${formatDateTime(Date.now(), 'HHmmDDMMYYYY')}`);
  body.append('file', file);

  try {
    const response = await uploadVideoAPI({
      body,
      onUploadProgress: progressEvent => {
        if (progressEvent.total) {
          const progress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          const uploadStatus = progress === 100 ? UPLOAD_STATUS.DONE : UPLOAD_STATUS.UPLOADING;
          showLoadingModal({
            modalInfo,
            progress,
            uploadStatus,
          });
        }
      },
    });
    return response.data.data;
  } catch (error) {
    showLoadingModal({
      modalInfo,
      progress: 100,
      uploadStatus: UPLOAD_STATUS.ERROR,
    });
  }
};
