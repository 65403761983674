import {Navigate, type RouteProps} from 'react-router-dom';

import {useAppSelector} from '~store';
import {usePermission} from '~hooks';
import {AllPermission} from '~variables';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

type Props = RouteProps & {
  title: string;
  auth?: boolean;
  permission: string;
};

const WrapperRoute = ({title, auth = true, permission, ...props}: Props) => {
  document.title = title;

  const user = useAppSelector(state => state.authReducer.user);

  const {allowedPermissions} = usePermission();

  if (permission !== AllPermission.All__Allowed && !allowedPermissions.includes(permission)) {
    return <Navigate to="/not-found" />;
  }

  const CommonRoute = auth ? PrivateRoute : PublicRoute;

  return <CommonRoute user={user} {...props} />;
};

export default WrapperRoute;
