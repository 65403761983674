import dayjs from 'dayjs';

import {Base, CommonObject} from '~models';

export const cloneDeep = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const isNullUndefined = (value: unknown): value is unknown => {
  return value === undefined || value === null;
};

export const classNames = (...classes: (string | CommonObject<string>)[]): string => {
  return classes
    .map(cls => {
      if (typeof cls === 'string') return cls;
      return Object.keys(cls).filter(key => !!cls[key]);
    })
    .flat(Infinity)
    .join(' ');
};

export const convertToOption = <O extends Base>(obj: O) => ({
  ...obj,
  label: obj.name,
  value: obj.id,
});

export const formatDateTime = (dateTime: string | number, template: string = 'DD/MM/YYYY') => {
  return dayjs(dateTime).format(template);
};

export const delay = (time: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(''), time);
  });
};
