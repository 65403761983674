import {DatePicker as AntdDatePicker, DatePickerProps} from 'antd';

import {classNames} from '~utils';

import Text from '../Text';

import './styles.scss';

type Props = DatePickerProps & {
  className?: string;
  label?: string;
  horizontal?: boolean;
  errorMsg?: string;
  required?: boolean;
};

const DatePicker = ({className, errorMsg, horizontal, label, required, ...props}: Props) => {
  return (
    <div
      className={classNames(`date-picker ${className}`, {
        'date-picker--horizontal': horizontal,
      })}>
      {label && (
        <div className="date-picker__label">
          <Text className="date-picker__label-text">{label}</Text>
          {required && <Text className="date-picker__label-required">*</Text>}
        </div>
      )}
      <AntdDatePicker className="date-picker__main" status={errorMsg && 'error'} {...props} />
      {errorMsg && <Text className="date-picker__error">{errorMsg}</Text>}
    </div>
  );
};

export default DatePicker;
