export const AllPermission = {
  Devices__Create: 'devices__Create',
  Devices__Read: 'devices__Read',
  Devices__Update: 'devices__Update',
  Devices__Delete: 'devices__Delete',

  Contents__Create: 'contents__Create',
  Contents__Read: 'contents__Read',
  Contents__Update: 'contents__Update',
  Contents__Delete: 'contents__Delete',

  Group_permissions__Create: 'group_permissions__Create',
  Group_permissions__Read: 'group_permissions__Read',
  Group_permissions__Update: 'group_permissions__Update',
  Group_permissions__Delete: 'group_permissions__Delete',

  All__Allowed: '*',
};
