import {useEffect, useMemo, useState} from 'react';

import {getMenuOpenKeys} from '~utils';

import {useMemoLocation} from './useMemoLocation';

interface Options {
  collapsed: boolean;
}

export const useMenu = ({collapsed}: Options) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const {pathname} = useMemoLocation();

  useEffect(() => {
    if (!collapsed) {
      const currOpenKeys = getMenuOpenKeys(pathname);
      setOpenKeys(currOpenKeys);
    }
    setSelectedKeys([pathname]);
  }, [collapsed, pathname]);

  return useMemo(
    () => ({
      openKeys,
      setOpenKeys,
      selectedKeys,
    }),
    [openKeys, selectedKeys],
  );
};
