import {SVGAttributes} from 'react';

const EditIcon = ({fill = '#292D32', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill="#F5F5F5" />
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke="#EEE" />
    <g clipPath="url(#clip0_4892_8456)">
      <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.462 19.2c-.381 0-.737-.132-.994-.376-.325-.306-.481-.769-.425-1.269l.232-2.024c.043-.382.274-.888.543-1.163l4.277-4.527.008-.008.847-.896c1.28-1.357 2.618-1.394 3.974-.113 1.357 1.281 1.394 2.619.113 3.975l-5.131 5.431c-.263.282-.75.544-1.132.607l-2.012.343-.11.009c-.064.005-.126.01-.19.01zm.037-4.194l3.7-3.92a4.287 4.287 0 002.72 2.578l-3.7 3.917a1.397 1.397 0 01-.607.325l-2.012.343c-.207.032-.375-.006-.488-.112-.113-.107-.162-.275-.137-.482l.23-2.025c.026-.18.17-.493.294-.625zm7.85-2.857l-.69.732a3.358 3.358 0 01-2.714-2.586l.686-.727c.425-.45.843-.75 1.325-.75.387 0 .819.2 1.319.681 1.13 1.063.85 1.825.075 2.65zm-9.474 9.07h11.25a.472.472 0 00.468-.47.472.472 0 00-.468-.468H8.875a.472.472 0 00-.47.469c0 .256.213.468.47.468z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_4892_8456">
        <path fill="#fff" transform="translate(7 7)" d="M0 0H15V15H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon;
