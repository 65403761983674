import {Flex} from 'antd';
import {useEffect, useState} from 'react';

import {Text} from '~components';
import {DeviceStatistic} from '~models';
import {getDeviceStatisticAPI} from '~services';

import './styles.scss';

const DeviceOnlineStatistic = () => {
  const [statistic, setStatistic] = useState<DeviceStatistic>({
    active: 0,
    non_active: 0,
  });

  useEffect(() => {
    getDeviceStatisticAPI().then(response => {
      setStatistic(response.data.data);
    });
  }, []);

  return (
    <Flex className="device-online-statistic" align="center" gap={30}>
      <Flex align="center">
        <Text>Số thiết bị đang online:&nbsp;</Text>
        <Text className="online-number">{statistic.active}</Text>
      </Flex>

      <Flex align="center">
        <Text>Số thiết bị đang offline:&nbsp;</Text>
        <Text className="offline-number">{statistic.non_active}</Text>
      </Flex>
    </Flex>
  );
};

export default DeviceOnlineStatistic;
