import {SVGAttributes} from 'react';

const PlayIcon = ({fill = '#333', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={29}
    height={29}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill="#F5F5F5" />
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke="#EEE" />
    <g clipPath="url(#clip0_4892_8442)">
      <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
      <path
        d="M11.918 20.3c-.493 0-.962-.12-1.375-.357-.975-.563-1.512-1.706-1.512-3.213v-4.456c0-1.512.537-2.65 1.512-3.212.975-.563 2.232-.457 3.544.3l3.856 2.225c1.306.756 2.031 1.793 2.031 2.918 0 1.125-.718 2.163-2.03 2.92l-3.857 2.224c-.756.431-1.494.65-2.169.65zm0-10.663c-.337 0-.637.075-.906.23-.675.388-1.044 1.245-1.044 2.407v4.45c0 1.163.369 2.013 1.044 2.406.675.394 1.6.282 2.606-.3l3.856-2.225c1.007-.58 1.563-1.325 1.563-2.106 0-.781-.556-1.525-1.563-2.106l-3.856-2.225c-.612-.35-1.187-.531-1.7-.531z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_4892_8442">
        <path fill="#fff" transform="translate(7 7)" d="M0 0H15V15H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PlayIcon;
