import {createSlice} from '@reduxjs/toolkit';

interface DeviceState {}

const initialState: DeviceState = {};

const deviceSlice = createSlice({
  name: 'DEVICE_SLICE',
  initialState,
  reducers: {},
});

export const deviceReducer = deviceSlice.reducer;
export * from './async-actions';
// export const {} = deviceSlice.actions;
