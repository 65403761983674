import {Menu} from 'antd';
import {useContext, useMemo} from 'react';

import {useMenu, usePermission} from '~hooks';
import {navigate} from '~routes';
import {menuItems} from '~variables';
import {SiderContext} from '~contexts';

const SiderMenu = () => {
  const {collapsed} = useContext(SiderContext);

  const {allowedPermissions} = usePermission();
  const {openKeys, setOpenKeys, selectedKeys} = useMenu({collapsed});

  const accessedMenuItems = useMemo(() => {
    return menuItems.filter(item => allowedPermissions.includes(item.permission));
  }, [allowedPermissions]);

  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      onSelect={({key}) => navigate(key)}
      openKeys={openKeys}
      onOpenChange={keys => setOpenKeys(keys)}
      items={accessedMenuItems}
    />
  );
};

export default SiderMenu;
