import {useMemo} from 'react';
import {Pagination as AntdPagination, PaginationProps as AntdPaginationProps} from 'antd';

import {classNames} from '~utils';
import {NextIcon, PreviousIcon} from '~assets';
import {defaultPageSizeOptions} from '~variables';

import Select from '../Select';
import Text from '../Text';
import './styles.scss';

export interface PaginationProps extends Omit<AntdPaginationProps, 'pageSize'> {
  pageSize: number;
  from?: number;
  to?: number;
  hideSizeChanger?: boolean;
  hideTotal?: boolean;
}

const Pagination = ({
  total,
  from,
  to,
  current,
  pageSize,
  pageSizeOptions,
  hideSizeChanger = false,
  hideTotal = false,
  onChange,
  onShowSizeChange,
}: PaginationProps) => {
  const memorizedPageSizeOptions = useMemo(() => {
    return (pageSizeOptions ?? defaultPageSizeOptions).map(size => ({
      value: size,
      label: size,
    }));
  }, [pageSizeOptions]);

  const renderPagination: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') return <PreviousIcon />;
    if (type === 'next') return <NextIcon />;
    return originalElement;
  };

  return (
    <div className="pagination">
      <div
        className={classNames('pagination__size', {
          'pagination__size--hide': hideSizeChanger,
        })}>
        <Text>Số hàng trên trang :</Text>
        <Select
          className="pagination__size-select"
          value={pageSize}
          options={memorizedPageSizeOptions}
          onChange={value => onShowSizeChange?.(Number(current), value)}
        />
      </div>
      <Text
        className={classNames('pagination__total', {
          'pagination__total--hide': hideTotal,
        })}>
        Hiển thị {from}-{to} trên số {total}
      </Text>
      <AntdPagination
        className="pagination__pages"
        showSizeChanger={false}
        current={current}
        pageSize={pageSize}
        total={total}
        itemRender={renderPagination}
        onChange={onChange}
      />
    </div>
  );
};

export default Pagination;
