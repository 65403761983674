import {SVGAttributes} from 'react';

const MediaContentIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.75c-.41 0-.75-.34-.75-.75v-.715c-2.353.232-3.5 1.617-3.5 4.215V16c0 2.86 1.39 4.25 4.25 4.25h8c2.86 0 4.25-1.39 4.25-4.25V7.5c0-2.598-1.147-3.983-3.5-4.215V4c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.75h-6.5V4c0 .41-.34.75-.75.75zm-.75-2.969V1c0-.41.34-.75.75-.75s.75.34.75.75v.75h6.5V1c0-.41.34-.75.75-.75s.75.34.75.75v.781c3.186.273 5 2.327 5 5.719V16c0 3.65-2.1 5.75-5.75 5.75H6C2.35 21.75.25 19.65.25 16V7.5c0-3.392 1.814-5.446 5-5.719zM14 10.75H6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zm-8 5h4c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H6c-.41 0-.75.34-.75.75s.34.75.75.75z"
      fill="#fff"
    />
  </svg>
);

export default MediaContentIcon;
