import {useMemo} from 'react';

import {useAppSelector} from '~store';
import {AllPermission} from '~variables';

export const usePermission = () => {
  const user = useAppSelector(state => state.authReducer.user);

  return useMemo(() => {
    const isAdmin = true;
    const groupPermissions = user?.group_permissions ?? [];

    return {
      user,
      allowedPermissions: isAdmin
        ? Object.values(AllPermission)
        : groupPermissions.map(g => g.permissions.map(p => `${p.module}__${p.action}`)).flat(),
    };
  }, [user]);
};
