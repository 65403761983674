import type {ThemeConfig} from 'antd';

export const configThemes: ThemeConfig = {
  token: {
    fontFamily: 'Be Vietnam Pro',
    lineWidthFocus: 0,
    fontSize: 15,
    colorPrimary: '#2560E5',
  },
  components: {
    Layout: {
      siderBg: '#c92127',
      headerBg: '#ffffff',
      headerHeight: 72,
    },
    Menu: {
      colorBgContainer: 'transparent',
      itemHoverBg: '#ef4444',
      itemSelectedBg: '#ef4444',
      itemActiveBg: '#da403d',
      itemColor: '#ffffff',
      itemHoverColor: '#ffffff',
      itemSelectedColor: '#ffffff',
      itemBorderRadius: 0,
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemHeight: 72,
      // popupBg: '#000', // TODO: Not working
      fontSize: 16,
    },
    Table: {
      headerBg: '#fff',
      headerColor: '#525252',
      colorText: '#333',
      fontSize: 14,
    },
    Button: {
      fontSize: 16,
      fontSizeLG: 16,
      controlHeight: 40,
      borderRadiusLG: 4,
      borderRadius: 4,
    },
    Modal: {
      paddingMD: 0,
      paddingContentHorizontalLG: 0,
    },
    Form: {
      fontSize: 12,
    },
    Tooltip: {
      colorBgSpotlight: '#fff',
      colorTextLightSolid: '#333',
      fontWeightStrong: 300,
      paddingSM: 28,
      paddingXS: 20,
    },
    Tag: {
      colorSuccess: '#15803D',
      colorSuccessBg: '#DCFCE7',
      colorSuccessBorder: '#DCFCE7',
      colorError: '#EF4444',
      colorErrorBg: '#FEF2F2',
      colorErrorBorder: '#FEF2F2',
      colorInfo: '#2560E5',
      colorInfoBg: '#FFF',
      colorInfoBorder: '#2560E5',
    },
    Pagination: {
      itemActiveBg: '#DB1B33',
      colorPrimary: '#FFF',
      colorPrimaryHover: '#FFF',
      colorText: '#333',
    },
    Empty: {
      fontSize: 14,
    },
    Switch: {
      colorPrimary: '#00B578',
      colorPrimaryHover: '#03C986',
      // trackHeight: 26,
      // handleSize: 22,
    },
    Input: {
      fontSize: 14,
      lineHeight: 1.3,
    },
    Select: {
      fontSize: 14,
    },
    FloatButton: {
      colorBgElevated: '#C92127',
    },
    Checkbox: {
      fontSize: 14,
      lineHeight: 20 / 14,
      colorText: '#333',
    },
    Upload: {
      colorBorder: '#60a5fa',
      colorFillAlter: '#EFF6FF',
    },
  },
};
