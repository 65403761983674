import {SVGAttributes} from 'react';

const AddMediaContentIcon = (props: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={17}
    height={18}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_4870_32729)" fill="#fff">
      <path transform="translate(0 .5)" fillOpacity={0.01} d="M0 0H17V17H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.846 12.364h1.82c.29 0 .531-.241.531-.532 0-.29-.24-.53-.531-.53h-1.82c-1.332 0-1.899-.567-1.899-1.9V4.346c0-1.331.567-1.898 1.899-1.898h3.237c1.331 0 1.898.567 1.898 1.898 0 .29.24.532.531.532.29 0 .531-.241.531-.532 0-1.912-1.055-2.96-2.96-2.96H3.846C1.933 1.385.885 2.44.885 4.345v5.058c0 1.912 1.055 2.96 2.96 2.96zm6.07 4.25h3.237c1.913 0 2.96-1.049 2.96-2.961V8.595c0-1.912-1.054-2.96-2.96-2.96H9.916c-1.912 0-2.96 1.055-2.96 2.96v5.058c0 1.912 1.055 2.96 2.96 2.96zM8.018 8.595c0-1.331.566-1.898 1.898-1.898h3.237c1.332 0 1.898.567 1.898 1.898v5.058c0 1.332-.566 1.898-1.898 1.898H9.916c-1.332 0-1.898-.566-1.898-1.898V8.595zm3.138 3.06h-.617a.535.535 0 01-.53-.53c0-.291.24-.532.53-.532h.617v-.616c0-.29.24-.532.53-.532.291 0 .532.241.532.532v.616h.623c.29 0 .532.24.532.531 0 .29-.241.531-.532.531h-.623v.624c0 .29-.24.53-.531.53a.535.535 0 01-.531-.53v-.624z"
      />
    </g>
    <defs>
      <clipPath id="clip0_4870_32729">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0H17V17H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default AddMediaContentIcon;
