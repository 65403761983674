import {Flex, Modal, ModalFuncProps, Progress} from 'antd';

import {Text} from '~components';
import {UPLOAD_STATUS} from '~models';

import {delay} from './common';

export const showConfirmModal = (props: ModalFuncProps) => {
  Modal.confirm({
    okText: 'Đồng ý',
    cancelText: 'Huỷ',
    closable: true,
    maskClosable: true,
    okButtonProps: {
      loading: false,
      type: 'primary',
    },
    ...props,
  });
};

const getProgressStatus = (uploadStatus: UPLOAD_STATUS) => {
  if (uploadStatus === UPLOAD_STATUS.DONE) return 'success';
  if (uploadStatus === UPLOAD_STATUS.ERROR) return 'exception';
  return 'normal';
};

export const showLoadingModal = async (props: {
  modalInfo: any;
  progress: number;
  uploadStatus: UPLOAD_STATUS;
}) => {
  props.modalInfo.update({
    open: true,
    content: (
      <Flex vertical align="center" gap={20} style={{padding: 20}}>
        <Text>Đang tải video lên</Text>
        <Progress percent={props.progress ?? 0} status={getProgressStatus(props.uploadStatus)} />
      </Flex>
    ),
  });

  if ([UPLOAD_STATUS.DONE, UPLOAD_STATUS.ERROR].includes(props.uploadStatus)) {
    await delay(2500);
    props.modalInfo.destroy();
  }
};

export const destroyAllModal = () => {
  Modal.destroyAll();
};
