import {createSlice} from '@reduxjs/toolkit';

interface MediaContentState {}

const initialState: MediaContentState = {};

const mediaContentSlice = createSlice({
  name: 'MEDIA_CONTENT_SLICE',
  initialState,
  reducers: {},
});

export const mediaContentReducer = mediaContentSlice.reducer;
export * from './async-actions';
// export const {} = mediaContentSlice.actions;
