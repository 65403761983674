import {Attachment, MediaContent} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';
import {AxiosProgressEvent} from 'axios';

interface GetMediaContentsAPIParams {
  created_at?: string;
  search?: string;
  page?: number;
  per_page?: number;
}

interface AddMediaContentAPIBody {
  name: string;
  description?: string;
  attachment: string;
  device_ids: string[];
}

interface UploadVideoAPIPayload {
  body: FormData;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

interface EditMediaContentPayload {
  id: string;
  name: string;
  description?: string;
  attachment?: string;
  device_ids: string[];
}

interface EditMediaContentStatusPayload {
  id: string;
  playing: boolean;
}

export const getMediaContentsAPI = (params: GetMediaContentsAPIParams) => {
  return axiosClient.get<ListResponse<MediaContent>>('/plans', {params});
};

export const getMediaContentByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<MediaContent>>(`/plans/${id}`);
};

export const addMediaContentAPI = (body: AddMediaContentAPIBody) => {
  return axiosClient.post<SuccessResponse>('/plans', body);
};

export const uploadVideoAPI = ({body, onUploadProgress}: UploadVideoAPIPayload) => {
  return axiosClient.post<ObjectResponse<Attachment>>('/attachment', body, {
    onUploadProgress,
  });
};

export const deleteVideoAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/attachment/${id}`);
};

export const editMediaContentAPI = ({id, ...body}: EditMediaContentPayload) => {
  return axiosClient.put<SuccessResponse>(`/plans/${id}`, body);
};

export const editMediaContentStatusAPI = ({id, ...body}: EditMediaContentStatusPayload) => {
  return axiosClient.put<SuccessResponse>(`/plans/${id}/change-status`, body);
};

export const deleteMediaContentAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/plans/${id}`);
};

export const replayMediaContentAPI = (id: string) => {
  return axiosClient.put<SuccessResponse>(`/plans/${id}/retry-play`);
};
