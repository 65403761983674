import {Flex} from 'antd';
import {ChangeEvent} from 'react';

import {DatePicker, Input} from '~components';
import {MediaContentFilterParams} from '~models';
import {useDebounceFunction} from '~hooks';

import './styles.scss';

interface Props {
  searchParams: MediaContentFilterParams;
  setSearchParams: (values: MediaContentFilterParams) => void;
}

const MediaContentFilter = ({searchParams, setSearchParams}: Props) => {
  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  return (
    <Flex className="media-content-filter" align="center" gap={24}>
      <Input
        className="field"
        label="Tên nội dung"
        placeholder="Nhập tên"
        horizontal
        allowClear
        defaultValue={searchParams.search}
        onChange={handleSearchChange}
      />
      <DatePicker
        className="field"
        label="Ngày tạo"
        placeholder="---Chọn ngày---"
        horizontal
        onChange={(_, created_at) => setSearchParams({created_at})}
      />
    </Flex>
  );
};

export default MediaContentFilter;
