import {useMemo} from 'react';
import {PlusCircleOutlined} from '@ant-design/icons';

import {useGetData, usePermission} from '~hooks';
import {navigate} from '~routes';
import {getMediaContentsThunk} from '~reducers';
import {Button, PageHeader, Table} from '~components';
import {MediaContent, MediaContentFilterParams} from '~models';

import {getColumns} from './utils';
import {MediaContentFilter} from './components';
import './styles.scss';

const MediaContentPage = () => {
  const {user} = usePermission();

  const {
    isLoading,
    data: mediaContents,
    pagination,
    searchParams,
    setSearchParams,
    dispatch,
    getData: getMediaContents,
  } = useGetData<MediaContent, MediaContentFilterParams>(getMediaContentsThunk);

  const columns = useMemo(() => {
    return getColumns({
      user,
      pagination,
      searchTerm: searchParams.search,
      mediaContentsLength: mediaContents.length,
      dispatch,
      getMediaContents,
      setSearchParams,
    });
  }, [
    user,
    pagination,
    searchParams.search,
    mediaContents.length,
    dispatch,
    getMediaContents,
    setSearchParams,
  ]);

  return (
    <div className="media-content">
      <PageHeader
        title="DANH SÁCH NỘI DUNG"
        actions={
          <Button
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            onClick={() => navigate('/media-content/add')}>
            Thêm mới
          </Button>
        }
      />

      <MediaContentFilter searchParams={searchParams} setSearchParams={setSearchParams} />

      <Table
        className="media-content__table"
        emptyText="Danh sách trống"
        loading={isLoading}
        columns={columns}
        dataSource={mediaContents}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />
    </div>
  );
};

export default MediaContentPage;
