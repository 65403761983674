import {SVGAttributes} from 'react';

const PlusIcon = ({fill = '#2560E5', ...props}: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.253 13.001c0 .41.34.75.75.75s.75-.34.75-.75v-5.25h5.25c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-5.25v-5.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v5.25h-5.25c-.41 0-.75.34-.75.75s.34.75.75.75h5.25v5.25z"
      fill={fill}
    />
  </svg>
);

export default PlusIcon;
