import {ButtonProps, Button as AntdButton, Flex} from 'antd';

import './styles.scss';
import {ReactNode} from 'react';

interface Props extends ButtonProps {
  children: ReactNode;
  className?: string;
  suffixIcon?: ReactNode;
}

const Button = ({className, children, suffixIcon, ...props}: Props) => {
  return (
    <AntdButton className={`button ${className}`} {...props}>
      {children}
      {suffixIcon}
    </AntdButton>
  );
};

export default Button;
